@import 'src/styles/variables/variables';

.MobileAppConversionBanner {
  @include primaryFont(600);
  align-items: center;
  background-color: white;
  display: flex;
  flex-direction: row;
  height: 76px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999999999999999;
}

.DismissButton {
  background-color: $white;
  border: 0;
  margin: 0 10px;
  padding: 0;

  span {
    height: 24px;
    width: 24px;
    color: $mutedGray;
  }
}

.IosAppIcon {
  border-radius: 10px;
  width: 45px;
  height: 45px;
  margin: 0 10px 0 0;
}

.DetailsContainer {
  margin-right: 10px;
  flex: 1;

  p {
    margin: 0;
    padding: 0;
  }

  .Text {
    min-height: 11px;
    overflow: hidden;
    color: $core1;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: -0.2px;
    max-width: 190px;
  }

  .Reviews {
    display: flex;
    align-items: center;

    .Ratings {
      gap: 2px;
    }

    .ReviewCount {
      color: #888888;
      font-size: 14px;
      margin-left: 2px;
      position: relave;
      transform: translateY(1px);
    }
  }
}

.OpenButton {
  @include primaryFont;
  background-color: #cd8f52;
  box-shadow: none;
  font-size: 12px;
  font-weight: 500 !important;
  height: auto;
  margin-right: 10px;
  min-width: 60px;
  padding: 10px 10px;
  text-transform: uppercase;
}
